var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{attrs:{"no-body":""}},[_c('app-data-table',{ref:"refProductsListTable",attrs:{"actions-table":{
        showEdit: _vm.$can('SERVICE_EDIT'),
        showShow: _vm.$can('OPERATOR_SERVICE_VIEW'),
        showDelete: _vm.$can('SERVICE_DELETE'),
      },"bottom-table":{ totalCount: _vm.totalProducts },"fields":_vm.tableColumns,"items":_vm.fetchServices,"small":_vm.$store.getters['app/xlAndDown'],"top-table":{
        showAdd: _vm.$can('SERVICE_ADD'),
        showSearch: true,
        searchFilterOptions: _vm.searchFilterOptions,
      },"table-name":"product-list"},on:{"delete":function($event){return _vm.deleteService($event.item.id)},"edit":function($event){_vm.$router.push({
          name: 'productEdit',
          params: {
            service_id: $event.item.id,
            typology: _vm.kebabCase($event.item.typology),
            tab: 'main-information',
          },
        })},"show":function($event){_vm.$router.push({
          name: 'productShow',
          params: {
            service_id: $event.item.id,
            typology: _vm.kebabCase($event.item.typology),
            tab: 'main-information',
          },
        })},"table-add":function($event){return _vm.$router.push({ name: 'serviceAdd' })}},scopedSlots:_vm._u([{key:"head(status)",fn:function(ref){
        var label = ref.label;
return [_c('div',{staticClass:"pl-75"},[_vm._v(_vm._s(label))])]}},{key:"cell(id)",fn:function(ref){
        var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"disabled":!_vm.$can('SERVICE_EDIT'),"to":{
            name: 'productEdit',
            params: {
              service_id: item.id,
              typology: _vm.kebabCase(item.typology),
              tab: 'main-information',
            },
          }}},[_vm._v(" "+_vm._s(item.baseReference)+" ")])]}},{key:"cell(name)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("trans")(item.name)))])])]}},{key:"cell(organization)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(item.organization.legalName))])])]}},{key:"cell(mainCategoryName)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("trans")(item.mainCategory.name)))])])]}},{key:"cell(status)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"variant":("light-" + (_vm.resolveStatusVariant(item.state))),"pill":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.resolveStatusText(item.state)))+" ")])]}},{key:"cell(visible)",fn:function(ref){
          var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.visible,"disabled":"","inline":"","name":"check-button","switch":""}})]}},{key:"cell(type)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("enumTranslate")(item.typology,'product_typology')))])])]}},{key:"cell(onQuote)",fn:function(ref){
          var item = ref.item;
return [(item.mainVariant)?_c('b-form-checkbox',{attrs:{"checked":item.mainVariant.onQuote,"disabled":"","inline":"","name":"check-button","switch":""}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }